:root {
  --main-white: #fff;
  --main-steelblue: #80a3c0;
  --main-lightsteelblue: rgba(128 163 192 / 30%);
  --main-orange: #ff7c45;
  --main-dark-orange: #fb5712;
  --main-blue: #08566e;
  --text-main: #293144;
  --text-secondary: #648bac;
  --text-placeholder: #b4c8d9;
  --bgc-blue: #013f54;
  --bgc-dark-blue: #023749;
  --bgc-light: #ebf3f7;
  --bgc-gray-40: rgba(226 238 244 / 40%);
  --bgc-gray: #e2eef4;
  --bgc-gray-light: #e6eef1;
  --bgc-modal: rgba(0 0 0 / 25%);
  --btn-main-hover: #ff6626;
  --btn-main-disabled: rgba(255 124 69 / 50%);
  --btn-bgc-secondary: #ffe5da;
  --btn-secondary-hover: #ffbda2;
  --btn-secondary-disabled: #faece7;
}
